import { ReactElement } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import About from './About';
import Contact from './Contact';
import Home from './Home';
import Portfolio from './Portfolio';
import Nav from '../components/Nav';

import './App.css';

function App(): ReactElement {
    return (
        <Router>
            <div className="page">
                <Nav />
                <div className="App">
                    <Routes>
                        <Route path="/" Component={Home} />
                        <Route path="/home" Component={Home} />
                        <Route path="/about" Component={About} />
                        <Route path="/portfolio" Component={Portfolio} />
                        <Route path="/contact" Component={Contact} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
