import { ReactElement } from 'react';

import tv from '../images/tv.png';

import './Tv.css';

function Tv(): ReactElement {
    return (
        <div className="tv-container">
            <div className="tv-contents">
                <div className="screen"></div>
            </div>
            <img src={tv} className="tv" alt="tv" />
        </div>
    );
}

export default Tv;
