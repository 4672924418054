import { ReactElement } from 'react';

import Tv from '../components/Tv';

function Home(): ReactElement {
    return (
        <div className="Home">
            <Tv />
        </div>
    );
}

export default Home;
